<template>
  <div class="sp-broadcast-message" :class="classModifiers">
    <div class="sp-broadcast-message-container">
      <figure v-show="hasIcon" class="sp-broadcast-message__icon">
        <sp-icon v-if="icon" :size="props.iconSize" :name="icon" :fill-color="iconFillColor" />
        <slot v-else ref="iconContainer" name="icon" />
      </figure>

      <div class="sp-broadcast-message__content">
        <component :is="titleTag" class="sp-broadcast-message__title" :v-show="hasTitle" @click="collapseText">
          <slot ref="titleSlot" name="title" />
        </component>
        <div class="sp-broadcast-message__text">
          <slot />
        </div>
      </div>
    </div>

    <div class="sp-broadcast-message__actions">
      <slot name="action">
        <sp-broadcast-action v-if="action" :href="action.link">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span v-html="action.content"></span>
        </sp-broadcast-action>
      </slot>
      <div v-if="isDismissable" class="sp-broadcast-message__actions-dismiss" @click="dismissBroadcast">x</div>
    </div>
  </div>
</template>

<script setup>
import { useMediaQuery } from "@vueuse/core";
import { computed, onMounted, ref, watch } from "vue";

const props = defineProps({
  /**
   * The icon of the broadcast message.
   *
   * @type String
   * @default undefined
   */
  icon: {
    type: String,
    default: undefined,
  },

  iconSize: {
    type: String,
    default: undefined,
  },

  /**
   * The variant of the broadcast message.
   * The variant determines the background color of the broadcast message.
   *
   * @type String (default|inline|full)
   * @default default
   */
  variant: {
    type: String,
    default: "default",
    validator: (value) => ["default", "inline", "full"].includes(value),
  },
  /**
   * The level of the broadcast message.
   *
   * @type String (info|warning|alert)
   * @default info
   */
  level: {
    type: String,
    default: "info",
    validator: (value) => ["info", "warning", "alert"].includes(value),
  },
  /**
   * Whether the broadcast message is collapsable.
   *
   * @type Boolean
   * @default false
   */
  collapsable: {
    type: Boolean,
    default: false,
  },
  /**
   * The tag of the title of the broadcast message.
   *
   * @type String (HTMLElement)
   * @default span
   */
  titleTag: {
    type: String,
    default: "span",
  },
  /**
   * The action of the broadcast message.
   *
   * @type Object - { link: String, content: String }
   * @default undefined
   */
  action: {
    type: Object,
    default: undefined,
  },
  /**
   * Whether the broadcast message is dismissable.
   *
   * @type Boolean
   * @default false
   */
  dismissable: {
    type: Boolean,
    default: false,
  },
  inline: {
    type: Boolean,
    default: false,
  },
});

const iconContainer = ref(null);
const hasIcon = computed(() => props.icon || iconContainer.value?.assignedNodes?.().length > 0);
const titleSlot = ref(null);
const hasTitle = computed(() => titleSlot.value?.assignedNodes?.().length > 0);

const smBreakpoint = computed(() =>
  window.getComputedStyle(document.documentElement).getPropertyValue("--sp-ref-breakpoint-sm"),
);

const xlBreakpoint = computed(() =>
  window.getComputedStyle(document.documentElement).getPropertyValue("--sp-ref-breakpoint-xl"),
);

const isGtSmScreen = useMediaQuery(`(min-width: ${smBreakpoint.value})`);
const isGtXlScreen = useMediaQuery(`(min-width: ${xlBreakpoint.value})`);

const isTextCollapsed = ref(props.collapsable);

function collapseText() {
  if (props.collapsable) {
    isTextCollapsed.value = !isTextCollapsed.value;
  }
}

const isDismissable = computed(() => props.dismissable === "true");
const isDismissed = ref(false);

function dismissBroadcast() {
  isDismissed.value = true;
}

const classModifiers = computed(() => ({
  [`--variant-${props.variant}`]: props.variant,
  [`--level-${props.level}`]: props.level,
  "--is-collapsable": props.collapsable,
  "--is-expanded": !isTextCollapsed.value,
  "--bp-min-width-sm": isGtSmScreen.value,
  "--bp-min-width-xl": isGtXlScreen.value,
  "--has-icon": hasIcon.value,
  "--is-dismissable": isDismissable.value,
  "--is-dismissed": isDismissed.value,
  "--content-inline": props.inline,
}));
const iconFillColor = computed(() => `var(--sp-comp-broadcast-message-level-${props.level}-icon-color)`);

/**
 * Sets the fill color of the icon.
 *
 * This is needed because the icon can either be a string or a slot.
 * If it is a slot, the fill color needs to be set on the icon itself using the `fill-color` attribute.
 * If it is a slot a reference to the slot is stored in the `iconContainer` ref.
 */
function setIconFillColor() {
  setIconAttribute("fill-color", iconFillColor.value);
}

function setIconAttribute(name, value) {
  if (!iconContainer.value) {
    return;
  }
  // find the sp-icon element in the slot
  const icon = iconContainer.value
    .assignedNodes()
    .filter(({ tagName }) => tagName === "SP-ICON")
    ?.at(0);

  icon?.setAttribute(name, value);
}

watch(iconFillColor, () => {
  setIconFillColor();
});

onMounted(() => {
  setIconFillColor();
});
</script>

<style>
:host {
  display: block;
}
</style>

<style lang="scss" scoped>
.sp-broadcast-message {
  --broadcast-text-color: inherit;
  --padding-block: var(--sp-comp-broadcast-message-padding-block, 1rem);
  --padding-inline: var(--sp-comp-broadcast-message-padding-inline, 1rem);
  --padding: var(--padding-block) var(--padding-inline);
  --grid-gap: var(--sp-comp-broadcast-message-grid-gap, 0.5rem);
  --actions-dismiss-size: var(--sp-comp-broadcast-message-actions-dismiss-size, 2rem);
  --dismissable-action-space: calc(var(--actions-dismiss-size) + var(--padding-inline) + var(--grid-gap));

  display: grid;
  gap: var(--grid-gap);
  grid-template-columns: 1fr auto; // content actions
  padding: var(--padding);
  position: relative;

  &.--is-dismissed {
    display: none;
  }

  &.--is-dismissable {
    padding-right: var(--dismissable-action-space);

    .sp-broadcast-message-container {
      transform: translateX(calc(var(--dismissable-action-space) / 2 - var(--grid-gap)));
    }
  }

  &.--variant-default {
    padding: var(--padding);
  }

  &.--variant-inline {
    border-radius: var(--sp-comp-broadcast-message-variant-inline-mobile-border-radius, 0);
    --padding-block: var(--sp-comp-broadcast-message-variant-inline-padding-block, 1rem);
    --padding-inline: var(--sp-comp-broadcast-message-variant-inline-padding-inline, 1rem);

    &.--bp-min-width-sm {
      border-radius: var(--sp-comp-broadcast-message-variant-inline-border-radius, 0);
    }

    .sp-broadcast-message-container {
      padding-inline: var(--sp-comp-broadcast-message-variant-inline-container-padding-inline, 0);
    }
  }

  &.--variant-full {
    --padding-block: var(--sp-comp-broadcast-message-variant-full-padding-block, 0.625rem);
    --padding-inline: var(--sp-comp-broadcast-message-variant-full-padding-inline, 0.625rem);
    --container-max-width: var(--sp-comp-broadcast-message-variant-full-container-max-width, 1170px);
    margin: 0;

    .sp-broadcast-message-container {
      justify-self: center;
      max-width: var(--container-max-width);
      padding-inline: var(--sp-comp-broadcast-message-variant-full-container-padding-inline, 10px);
    }

    &.--bp-min-width-xl {
      --container-max-width: var(--sp-comp-broadcast-message-variant-full-container-max-width-xl, 1280px);
    }
  }
  &.--content-inline:not(.--is-collapsable) {
    .sp-broadcast-message__title,
    .sp-broadcast-message__text {
      display: inline !important;
    }

    .sp-broadcast-message__title {
      &:after {
        content: " ";
        display: inline;
      }
      margin-bottom: 0;
    }
  }

  @each $level in ("info", "warning", "alert") {
    &.--level-#{$level} {
      --broadcast-text-color: var(--sp-comp-broadcast-message-level-#{$level}-text-color);
      background-color: var(--sp-comp-broadcast-message-level-#{$level}-background-color);
      color: var(--broadcast-text-color);
    }
  }

  &.--is-collapsable {
    .sp-broadcast-message__title,
    .sp-broadcast-message__text {
      padding-right: 1rem;
    }

    .sp-broadcast-message__title {
      display: block;
      position: relative;
      cursor: pointer;
      margin-bottom: 0;
      &::after {
        position: absolute;
        top: 0.475em;
        right: 0.3rem;
        border-style: solid;
        border-width: 0.3em 0.3em 0;
        content: "";
        transition: transform 0.2s ease-in-out;
        color: var(--broadcast-text-color, #000);
        border-color: var(--broadcast-text-color, #000) transparent transparent;
      }
    }

    .sp-broadcast-message__content .sp-broadcast-message__text {
      display: none;
    }

    &.--is-expanded {
      .sp-broadcast-message__title::after {
        transform: rotate(180deg);
      }

      .sp-broadcast-message__content .sp-broadcast-message__text {
        display: block;
        margin-top: 1rem;
      }
    }

    .sp-broadcast-message-container {
      width: 100%;
    }
  }

  &:not(.--has-icon) {
    .sp-broadcast-message__icon {
      width: 0;
      height: 0;
    }
  }
}

.sp-broadcast-message-container {
  box-sizing: border-box;
  display: grid;
  gap: var(--sp-comp-broadcast-message-grid-gap, 0.5rem);
  grid-template-columns: auto 1fr; // icon content
  align-items: center;
}

.sp-broadcast-message__icon {
  sp-icon {
    --size: var(--sp-comp-broadcast-message-icon-size, var(--sp-sys-icon-size-m, 2rem));
  }
  margin: 0;
  align-self: baseline;
}

.sp-broadcast-message__content {
  color: var(--broadcast-text-color);
  line-height: var(--sp-comp-broadcast-message-line-height, 1.2);
  padding-top: var(--sp-comp-broadcast-message-content-padding-top, 0.5rem);
  padding-bottom: var(--sp-comp-broadcast-message-content-padding-bottom, 0.5rem);
}

.sp-broadcast-message__title {
  display: block;
  font-family: var(--sp-comp-broadcast-message-title-typeface, sans-serif);
  font-size: var(--sp-comp-broadcast-message-title-size, 1rem);
  font-weight: var(--sp-comp-broadcast-message-title-weight, bold);
  line-height: var(--sp-comp-broadcast-message-line-height, 1.2);
  margin: 0;
  margin-bottom: var(--sp-comp-broadcast-message-title-margin-bottom, 1rem);
}

.sp-broadcast-message__text {
  display: block;
  font-family: var(--sp-comp-broadcast-message-text-typeface, sans-serif);
  font-size: var(--sp-comp-broadcast-message-text-size, 1rem);
  font-weight: var(--sp-comp-broadcast-message-text-weight, normal);
  line-height: var(--sp-comp-broadcast-message-line-height, 1.2);
}

.sp-broadcast-message__actions {
  display: flex;
}

.sp-broadcast-message__actions-dismiss {
  // TODO: replace with icon
  cursor: pointer;
  display: flex;
  font-family: sans-serif;
  align-items: center;
  justify-content: center;
  color: var(--sp-comp-broadcast-message-actions-dismiss-color, #000);
  height: var(--actions-dismiss-size);
  position: absolute;
  top: var(--padding-block);
  right: var(--padding-inline);
  width: var(--actions-dismiss-size);
}
</style>
