<template>
  <sp-text-field
    class="sp-search-box"
    :class="classModifiers"
    :value="model"
    :density="density"
    :readonly="$props.inputmode === 'none'"
    :placeholder="placeholder"
    @input="onTextInput"
  >
    <div slot="prepend" class="prepend">
      <slot name="prepend" @click="onPrependClick">
        <sp-icon name="search-filled" class="sp-search-box__icon" />
      </slot>
    </div>
    <div slot="append" class="append">
      <slot name="append" @click="onAppendClick">
        <sp-button
          v-if="showClearButton"
          class="sp-search-box__trailing-action"
          size="small"
          color="surface"
          icon="close-filled"
        />
      </slot>
    </div>
  </sp-text-field>
</template>

<script>
import { props as searchBoxProps } from "./props.js";
</script>

<script setup>
import { computed, ref, watch } from "vue";
import { toBoolean } from "../../utils/props";

const emit = defineEmits(["text-input", "click-prepend", "click-append"]);

const props = defineProps({
  ...searchBoxProps,
});

const model = ref(props.value);
watch(model, () => emit("text-input", model.value));
watch(
  () => props.value,
  (value) => (model.value = value),
);

const clearable = computed(() => toBoolean(props.clearable));

const showClearButton = computed(() => clearable.value && model.value?.length > 0);

function onTextInput({ detail }) {
  const [value] = detail;
  model.value = value;
}

function onPrependClick() {
  emit("click-prepend");
}

function onAppendClick() {
  if (clearable.value) {
    model.value = "";
  }
  emit("click-append");
}

const classModifiers = computed(() => ({
  "--clearable": toBoolean(props.clearable),
}));
</script>

<style>
:host {
  display: block;
}
</style>

<style lang="scss" scoped>
.sp-search-box {
  --sp-comp-text-field-padding-inline: 0 0.5rem;
  &.--clearable {
    --sp-comp-text-field-padding-inline: 0;
  }
}
.sp-search-box__icon {
  --fill: var(--sp-comp-text-field-icon-fill, var(--sp-sys-form-field-placeholder-color, #bbb));
}

.append,
.prepend {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
