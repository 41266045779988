<template>
  <sp-menu
    ref="root"
    class="date-filter"
    :open="menuOpen"
    :close-on-content-click="closeOnContentClick"
    @update-open="handleUpdateOpen"
  >
    <div slot="activator">
      <slot name="activator" :label="label" :text="activatorTitle" :prepend-icon="prependIcon" :open="open">
        <DefaultFilterActivator
          :value="activatorTitle"
          :label="label"
          :prepend-icon="prependIcon"
          no-divider
          class="date-filter__activator"
          @clear="clearSelectedModel"
        />
      </slot>
    </div>

    <sp-card>
      <sp-card-content>
        <sp-label block>
          {{ cardLabel }}
        </sp-label>
        <sp-date-picker-two
          ref="datePicker"
          v-bind="attrs"
          :value="model"
          :open="open"
          :name="name"
          :range="range"
          :inline="inline"
          :display-format="displayFormat"
          @update-open="handleUpdateOpen"
          @update-model-value="handleUpdateModelValue"
        ></sp-date-picker-two>
      </sp-card-content>
    </sp-card>
  </sp-menu>
</template>

<script setup>
import { watchImmediate } from "@vueuse/core";
import { computed, ref, useAttrs, watch } from "vue";
import { useForm } from "../../../composables/form";
import { toBoolean, trimToUndefined } from "../../../utils/props";
import DefaultFilterActivator from "./DefaultFilterActivator.ce.vue";

const emit = defineEmits(["update:open", "change", "update:model-value"]);

const attrs = useAttrs();

const root = ref(null);
const { syncFormData } = useForm(root);

const props = defineProps({
  modelValue: {
    type: String,
    default: undefined,
  },
  name: {
    type: String,
    default: "date",
  },
  label: {
    type: String,
    default: "Date",
  },
  cardLabel: {
    type: String,
    default: undefined,
  },
  displayFormat: {
    type: String,
    default: "F j, Y",
  },
  prependIcon: {
    type: String,
    default: "event-filled",
  },
  hidePointer: {
    type: Boolean,
    default: false,
  },
  open: {
    type: Boolean,
    default: false,
  },
  inline: {
    type: Boolean,
    default: false,
  },
  range: {
    type: [Boolean, String],
    default: false,
  },
  permanent: {
    type: [Boolean, String],
    default: false,
  },
  width: {
    type: String,
    default: undefined,
  },
  closeOnSelect: {
    type: [Boolean, String],
    default: false,
  },
  closeOnContentClick: {
    type: [Boolean, String],
    default: false,
  },
});

defineExpose({ props });

const menuOpen = ref(toBoolean(props.open));
const range = computed(() => toBoolean(props.range));

watch(
  () => props.open,
  (value) => (menuOpen.value = toBoolean(value)),
);
watch(menuOpen, (value) => {
  emit("update:open", value);
});

const model = ref(props.modelValue);

watch(
  () => props.modelValue,
  (value) => (model.value = value),
);

watchImmediate(model, (value) => emit("update:model-value", value));

syncFormData(model, { immediate: trimToUndefined(model.value) === undefined });

const datePicker = ref(null);
const displayValue = ref();

watchImmediate(
  () => [datePicker.value?.displayText, model.value],
  ([value]) => (displayValue.value = value),
);

const activatorTitle = computed(() => trimToUndefined(displayValue.value));

function clearSelectedModel() {
  model.value = undefined;
}

function handleUpdateModelValue({ detail }) {
  const [value] = detail;
  model.value = value;
}

function handleUpdateOpen({ detail }) {
  const [isOpen] = detail;
  menuOpen.value = isOpen;
}
</script>

<style scoped>
.date-filter {
  --sp-comp-popup-width: max-content !important;
}

sp-label {
  margin-top: var(--sp-ref-spacing-6);
  margin-bottom: var(--sp-ref-spacing-4);
}
</style>
