<template>
  <dialog ref="dialog" class="sp-dialog" part="base" :class="classModifiers" @keydown.esc="handleKeyDownEscape">
    <div ref="content" class="sp-dialog__content">
      <slot />
    </div>
  </dialog>
</template>

<script>
import { props as dialogProps } from "./props.js";

const State = {
  OPENING: "opening",
  OPEN: "open",
  CLOSING: "closing",
  CLOSED: "closed",
};
</script>

<script setup>
import { onClickOutside, onKeyStroke, useCssVar, useEventListener } from "@vueuse/core";
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { toBoolean } from "../../utils/props";

defineOptions({
  inheritAttrs: false,
});

const emit = defineEmits(["close", "update-open"]);

const props = defineProps({
  ...dialogProps,
  position: {
    type: String,
    default: "center",
    validator: (value) => ["center", "bottom"].includes(value),
  },
});

onKeyStroke("Escape", handleKeyDownEscape);

const dialog = ref(null);
useEventListener(dialog, "animationend", handleDialogAnimation);

const content = ref(null);
onClickOutside(content, conditionalHideDialog);

const mounted = ref(false);

const persistent = ref(toBoolean(props.persistent));
const ignoreEscapeKey = ref(toBoolean(props.ignoreEscapeKey));

const open = ref(toBoolean(props.open));

watch(open, (value) => {
  handleOpenChange(value);
  emit("update-open", value);
});

watch(
  () => props.open,
  (value) => (open.value = toBoolean(value)),
);

watch(
  () => props.isHiddenTemporarily,
  (value) => toggleTemporaryHiddenState(toBoolean(value)),
);

function toggleTemporaryHiddenState(isHidden) {
  if (!dialog.value) {
    return;
  }

  if (isHidden) {
    closeModal();
  } else {
    showModal();
  }
}

const state = ref(State.CLOSED);

const classModifiers = computed(() => ({
  "--no-backdrop": toBoolean(props.noBackdrop),
  "--persistent": toBoolean(props.persistent),
  "--fullscreen": toBoolean(props.fullscreen),
  "--scrollable": toBoolean(props.scrollable),
  [`--state-${state.value}`]: state.value,
  [`--position-${props.position}`]: props.position,
}));

/**
 * This is a reference to the body overflow css variable
 * it is used to control the body scroll when the dialog is open
 * due a bug in the dialog element that doesn't allow to set the overflow
 * property to hidden when the dialog is open
 *
 * If dialog is open, the body will have the overflow hidden
 * otherwise, the body will have the overflow inherit
 *
 * If the bug is fixed, this can be removed
 * @see https://github.com/whatwg/html/issues/7732
 * @type {import("vue").Ref<string>}
 */

const bodyScroll = useCssVar("--sp-body-overflow");

function showModal() {
  if (!mounted.value) {
    return;
  }
  state.value = State.OPENING;
  dialog.value.showModal();
}

function closeModal() {
  state.value = State.CLOSING;
}

/**
 * Fired when the dialog animation ends
 * This is used to detect when the dialog is opened or closed
 *
 * @param {AnimationEvent} event
 */
function handleDialogAnimation(event) {
  if (event.pseudoElement === "::backdrop") {
    return;
  }

  const openEnd = event.animationName.startsWith("open--");
  const closeEnd = event.animationName.startsWith("close--");

  if (state.value === State.OPENING && openEnd) {
    handleDialogOpenAnimationEnd();
  } else if (state.value === State.CLOSING && closeEnd) {
    handleDialogCloseAnimationEnd();
  }
}

function handleDialogOpenAnimationEnd() {
  state.value = State.OPEN;
}

function handleDialogCloseAnimationEnd() {
  state.value = State.CLOSED;
  open.value = false;
  dialog.value.close();
  emit("close");
}

async function conditionalHideDialog() {
  if (!open.value || persistent.value) {
    return;
  }

  closeModal();
}

function handleKeyDownEscape(event) {
  if (persistent.value || ignoreEscapeKey.value) {
    event.preventDefault();
    event.stopPropagation();
  } else {
    conditionalHideDialog();
  }
}

function handleOpenChange(isOpen) {
  if (isOpen) {
    showModal();
    bodyScroll.value = "hidden";
  } else {
    closeModal();
    bodyScroll.value = "inherit";
  }
}

onMounted(() => {
  mounted.value = true;

  if (open.value) {
    nextTick(showModal);
  }
});
</script>

<style></style>

<style lang="scss">
:host {
  display: block;
  --sp-comp-dialog-margin: auto;
  --sp-comp-dialog-width: auto;
  --sp-comp-dialog-max-inline-size: 60ch;
}

dialog {
  $backdrop-opacity: var(
    --sp-comp-dialog-backdrop-opacity,
    0.3
  ); // Safari (>=17.1) doesn't support --custom-property in ::backdrop
  --ease: ease-in-out;
  --animation-time: v-bind(animationTime);

  --animation-on-open: open--fade-in var(--animation-time) var(--ease);
  --animation-on-close: close--fade-out var(--animation-time) var(--ease);

  display: none;
  z-index: var(--sp-sys-z-index-max, 99);
  max-inline-size: min(90vw, var(--sp-comp-dialog-max-inline-size));

  position: fixed;
  width: v-bind(width);
  min-width: v-bind(width);
  height: var(--dialog-height, v-bind(height));
  min-height: var(--dialog-min-height, v-bind(height));
  max-height: var(--dialog-max-height, v-bind(height));
  overflow: hidden;
  opacity: 1;

  border-radius: var(--sp-comp-dialog-border-radius, 0.5rem);
  padding: var(--sp-comp-dialog-padding, 0);
  margin: var(--sp-comp-dialog-margin, auto);
  border: var(--sp-comp-dialog-border, 0);

  animation-timing-function: var(--ease);
  // transition: all 0.3s var(--ease);

  &::backdrop {
    background-color: var(--sp-comp-dialog-backdrop-background-color, #000);
    opacity: 0;
  }

  &.--state-closing,
  &.--state-opening,
  &.--state-open {
    display: block;
  }

  &.--state-opening,
  &.--state-closing {
    &::backdrop {
      animation: backdrop-fade var(--animation-time, 0.2s) ease-in-out;
    }
  }

  &.--state-open {
    &::backdrop {
      opacity: $backdrop-opacity;
    }
  }

  &.--state-opening {
    animation: var(--animation-on-open) forwards;

    &::backdrop {
      animation-direction: forwards;
    }
  }

  &.--state-closing {
    animation: var(--animation-on-close) forwards;

    &::backdrop {
      animation-direction: reverse;
    }
  }

  &.--state-closed {
    display: none;
  }

  &.--fullscreen {
    max-inline-size: 100vw;
    max-block-size: 100vh;
  }

  &.--no-backdrop::backdrop {
    background-color: transparent;
  }

  &.--position-bottom {
    --animation-on-close: close--slide-out-down var(--animation-time) var(--ease);
    --animation-on-open: open--slide-in-up var(--animation-time) var(--ease);
    inset: auto 0 0 0;
    max-inline-size: 100vw;
  }

  &.--scrollable .sp-dialog__content {
    max-height: 90vh;
    overflow-y: auto;
  }

  &:not([open]) {
    pointer-events: none;
  }

  .sp-dialog__content {
    height: 100%;
  }

  @keyframes open--slide-in-up {
    0% {
      transform: translateY(100%);
    }
  }

  @keyframes close--slide-out-down {
    100% {
      transform: translateY(100%);
    }
  }

  @keyframes open--fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes close--fade-out {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes backdrop-fade {
    0% {
      opacity: 0;
    }

    100% {
      opacity: $backdrop-opacity;
    }
  }
}
</style>
