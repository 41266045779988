<template>
  <sp-row>
    <DiscountRowSummary v-if="hasDiscount" />
    <DiscountRowPromoCodeInput v-else-if="paymentRequired" />
  </sp-row>
</template>

<script setup>
import { computed } from "vue";
import { usePurchaseStore } from "../store/purchase";
import DiscountRowPromoCodeInput from "./DiscountRowPromoCodeInput.ce.vue";
import DiscountRowSummary from "./DiscountRowSummary.ce.vue";

const purchaseStore = usePurchaseStore();
const hasDiscount = computed(() => purchaseStore.hasDiscount);
const paymentRequired = computed(() => purchaseStore.paymentRequired.value);
</script>
