<template>
  <div class="sp-stepper-window-item">
    <slot />
  </div>
</template>

<style>
:host {
  display: block;
  height: 100%;
  box-sizing: border-box;
}

.sp-stepper-window-item {
  box-sizing: border-box;
  height: 100%;
}
</style>
