<template>
  <a class="sp-navigation-item">
    <slot />
  </a>
</template>

<style scoped lang="scss">
.sp-navigation-item {
  color: var(--sp-comp-navigation-item-color, var(--sp-sys-color-link, #ffffff));
  text-decoration: var(--sp-comp-navigation-item-text-decoration, var(--sp-sys-link-text-decoration, none));

  &:hover {
    color: var(--sp-comp-navigation-item-color-hover, var(--sp-sys-color-link-hover, #ffffff));
    text-decoration: var(
      --sp-comp-navigation-item-text-decoration-hover,
      var(--sp-sys-link-text-decoration-hover, none)
    );
  }
}
</style>
