<template>
  <div class="sp-card" :class="classModifiers">
    <div class="sp-card__content-overlay">
      <slot name="content-overlay" />
    </div>
    <div class="sp-card__title">
      <slot name="title">
        <sp-card-title v-if="title">{{ title }}</sp-card-title>
      </slot>
    </div>
    <div class="sp-card__subtitle">
      <slot name="subtitle">
        <sp-card-subtitle v-if="subtitle">{{ subtitle }}</sp-card-subtitle>
      </slot>
    </div>
    <div class="sp-card__content">
      <slot />
    </div>
    <div class="sp-card__actions">
      <slot name="actions" />
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { toBoolean } from "../../utils/props";
const props = defineProps({
  title: {
    type: String,
    default: undefined,
  },
  subtitle: {
    type: String,
    default: undefined,
  },
  scrollableContent: {
    type: [Boolean, String],
    default: false,
  },
  grid: {
    type: [Boolean, String],
    default: false,
  },
});
const classModifiers = computed(() => ({
  "--grid": toBoolean(props.grid),
  "--scrollable-content": toBoolean(props.scrollableContent),
}));
</script>

<style>
:host {
  display: block;
  height: 100%;
}
</style>

<style scoped lang="scss">
.sp-card {
  --card-title-z-index: var(--sp-ref-z-index-max, 99);
  &.--grid {
    display: grid;
    grid-template-rows: auto auto 1fr auto;
    grid-template-areas: "title" "subtitle" "content" "actions";
    height: var(--sp-comp-card-height, auto);

    .sp-card__content {
      position: relative;
      overflow-y: hidden;
    }
  }
  &.--scrollable-content {
    .sp-card__content {
      overflow-y: auto !important;
    }
  }
}

.sp-card__title {
  grid-area: title;
  z-index: var(--card-title-z-index);
}

.sp-card__subtitle {
  grid-area: subtitle;
}

.sp-card__content {
  grid-area: content;
  position: relative;
  height: 100%;
}

.sp-card__actions {
  grid-area: actions;
}

.sp-card__content-overlay {
  z-index: calc(var(--card-title-z-index) - 1);
}
</style>
