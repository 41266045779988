<template>
  <div class="sp-stepper-window" part="base">
    <slot />
  </div>
</template>

<style>
:host {
  display: block;
  box-sizing: border-box;
}

:host([scrollable]:not([scrollable="false"])) {
  height: 100%;
  overflow: hidden;
}

:host([scrollable]:not([scrollable="false"])) ::slotted(*) {
  height: 100%;
}
</style>

<style scoped lang="scss">
.sp-stepper-window {
  height: 100%;
  box-sizing: border-box;
}
</style>
