<template>
  <div ref="root" :class="classModifiers" class="sp-date-picker-two">
    <input ref="datepicker" type="hidden" />
  </div>
</template>

<script>
const Mode = {
  Single: "single",
  Range: "range",
  Multiple: "multiple",
};
</script>

<script setup>
import { useEventListener, whenever } from "@vueuse/core";
import flatpickr from "flatpickr";
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from "vue";
import { useBreakpoints } from "../../composables/breakpoints";
import { useExpose } from "../../composables/expose";
import { useHost } from "../../composables/host";
import { toBoolean } from "../../utils/props";

const emit = defineEmits(["change", "update-model-value"]);

const props = defineProps({
  value: {
    type: [String, Date],
    default: undefined,
  },
  wrap: {
    type: Boolean,
    default: false,
  },
  inline: {
    type: Boolean,
    default: false,
  },
  mode: {
    type: String,
    default: Mode.Single,
    validator: (value) => Object.values(Mode).includes(value),
  },
  range: {
    type: Boolean,
    default: false,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  displayFormat: {
    type: String,
    default: "F j, Y",
  },
  tmpCssLink: {
    type: String,
    default: undefined,
  },
});

const root = ref(null);
const datepicker = ref(null);

const { rootNode } = useHost(root);

const { isSmScreen } = useBreakpoints();
const classModifiers = computed(() => ({
  "--is-small-screen": isSmScreen.value,
}));

/**
 * This is a workaround to load the temporary CSS link.
 * It should be removed once full theme support is implemented.
 *
 * TODO: @sleistner, @mgruschetzki
 */
watch(rootNode, () => {
  if (props.tmpCssLink) {
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = props.tmpCssLink;
    rootNode.value.appendChild(link);
  }
});

const { exposeProperties } = useExpose(root);

const calendar = ref(null);
const altInput = computed(() => calendar.value?.altInput);

exposeProperties({
  displayText: {
    get: () => altInput.value?.value,
  },
});

const model = ref(props.value);
watch(
  () => props.value,
  (value) => (model.value = value),
);
watch(model, (value) => {
  if (!value) {
    clearCalendar();
  }
});

function clearCalendar() {
  calendar.value?.clear();
}

const isRangeMode = computed(() => toBoolean(props.range));
const isMultipleMode = computed(() => toBoolean(props.multiple));

const mode = computed(() => {
  if (isMultipleMode.value) {
    return Mode.Multiple;
  }
  if (isRangeMode.value) {
    return Mode.Range;
  }
  return props.mode;
});

function initializeFlatpickr() {
  const config = {
    mode: mode.value,
    inline: toBoolean(props.inline),
    wrap: toBoolean(props.wrap),
    altInput: true,
    altFormat: props.displayFormat,
    altInputClass: "flatpickr-alt-input",
    onChange: handleChange,
    prevArrow: buildArrowIcon("arrow-left-filled"),
    nextArrow: buildArrowIcon("arrow-right-filled"),
  };

  calendar.value = flatpickr(datepicker.value, config);
  calendar.value.setDate(model.value, false);
}

function buildArrowIcon(name) {
  return `<sp-icon name='${name}' fill-color='var(--date-picker-icon-arrow-color)'></sp-icon>`;
}

/**
 * We wait for the calendar to become available and then delegate the click event from the navigation
 * icons to the parent element.
 *
 * This is necessary because flatpickr does not detect the click event on the icon.
 */
whenever(calendar, (instance) => {
  delegateIconEvent(instance.prevMonthNav);
  delegateIconEvent(instance.nextMonthNav);
});

/**
 * Delegates the click event from the icon to the parent element.
 *
 * @param {HTMLElement} parent - The parent element to which the event should be delegated.
 */
function delegateIconEvent(parent) {
  const target = parent.querySelector("sp-icon");
  useEventListener(target, "click", () => parent.dispatchEvent(new PointerEvent("click", { bubbles: true })));
}

function handleChange(selectedDates, value) {
  // If the range mode is enabled, but the user has not selected two dates, do not update the model value.
  if (isRangeMode.value && selectedDates.length !== 2) {
    return;
  }

  emit("update-model-value", value);
  emit("change", value);
}

onMounted(() => {
  nextTick(initializeFlatpickr);
});

onUnmounted(() => {
  calendar.value?.destroy();
});
</script>

<style lang="scss">
@import "flatpickr/dist/flatpickr.min.css";

.sp-date-picker-two {
  --date-picker-icon-arrow-color: var(--sp-comp-date-picker-arrow-icon-color, var(--sp-sys-color-primary));

  &.--is-small-screen {
    .flatpickr-rContainer {
      display: block !important;
      width: 100% !important;
    }
    .dayContainer,
    .flatpickr-days {
      width: 100% !important;
      min-width: 100% !important;
      max-width: 100% !important;
    }
  }
}

// flatpickr overrides

.flatpickr-alt-input {
  display: none;
}

.flatpickr-calendar {
  --selected-bg: var(--sp-comp-date-picker-selected-state-bg, var(--sp-sys-color-primary)) !important;
  --selected-text-color: var(
    --sp-comp-date-picker-selected-state-text-color,
    var(--sp-sys-color-on-primary)
  ) !important;

  box-shadow: none !important;
  padding: 0 !important;
  margin: 0 !important;
  font-family: var(--sp-sys-font-family-normal);
  font-weight: var(--sp-sys-font-weight-normal);

  .flatpickr-day.selected,
  .flatpickr-day.startRange,
  .flatpickr-day.endRange,
  .flatpickr-day.selected.inRange,
  .flatpickr-day.startRange.inRange,
  .flatpickr-day.endRange.inRange,
  .flatpickr-day.selected:focus,
  .flatpickr-day.startRange:focus,
  .flatpickr-day.endRange:focus,
  .flatpickr-day.selected:hover,
  .flatpickr-day.startRange:hover,
  .flatpickr-day.endRange:hover,
  .flatpickr-day.selected.prevMonthDay,
  .flatpickr-day.startRange.prevMonthDay,
  .flatpickr-day.endRange.prevMonthDay,
  .flatpickr-day.selected.nextMonthDay,
  .flatpickr-day.startRange.nextMonthDay,
  .flatpickr-day.endRange.nextMonthDay {
    background: var(--selected-bg) !important;
    border-color: var(--selected-bg) !important;
    color: var(--selected-text-color) !important;
  }

  .flatpickr-day.inRange,
  .flatpickr-day.prevMonthDay.inRange,
  .flatpickr-day.nextMonthDay.inRange,
  .flatpickr-day.today.inRange,
  .flatpickr-day.prevMonthDay.today.inRange,
  .flatpickr-day.nextMonthDay.today.inRange,
  .flatpickr-day:hover,
  .flatpickr-day.prevMonthDay:hover,
  .flatpickr-day.nextMonthDay:hover,
  .flatpickr-day:focus,
  .flatpickr-day.prevMonthDay:focus,
  .flatpickr-day.nextMonthDay:focus {
    --background: var(--sp-comp-date-picker-in-range-bg, var(--sp-sys-color-primary-container));
    border-color: var(--background) !important;
    background: var(--background) !important;
    color: var(--sp-comp-date-picker-in-range-text-color, var(--sp-sys-color-on-surface, #000)) !important;
    box-shadow:
      -5px 0 0 var(--background),
      5px 0 0 var(--background);
  }

  .flatpickr-day:hover,
  .flatpickr-day.prevMonthDay:hover,
  .flatpickr-day.nextMonthDay:hover,
  .flatpickr-day:focus,
  .flatpickr-day.prevMonthDay:focus,
  .flatpickr-day.nextMonthDay:focus {
    box-shadow: none !important;
  }

  .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
  .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
  .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
    box-shadow: -10px 0 0 var(--selected-bg);
  }

  .flatpickr-day.today {
    border: 2px solid var(--sp-comp-date-picker-today-border-color, var(--sp-sys-color-red, #b30005)) !important;
  }

  .flatpickr-current-month {
    position: relative;
    left: unset;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
    font-size: var(--sp-ref-font-size-large) !important;

    .flatpickr-monthDropdown-months {
      padding: 0;
    }
  }

  .flatpickr-months .flatpickr-prev-month,
  .flatpickr-months .flatpickr-next-month {
    position: relative;
    padding: 0;
    transition: all 0.3s ease-in-out;

    &:hover {
      opacity: 0.7;
    }
  }

  .flatpickr-months {
    margin-bottom: 0 !important;

    .flatpickr-month {
      order: 1;
    }

    .flatpickr-prev-month {
      order: 2;
    }

    .flatpickr-next-month {
      order: 3;
    }
  }
  .flatpickr-weekdays {
    border-bottom: 1px solid var(--sp-comp-date-picker-weekdays-border-color, var(--sp-sys-color-outline-variant));
  }
}
</style>
