import { onMounted, ref } from "vue";

export function useHost(root) {
  const host = ref(null);
  const rootNode = ref(null);

  onMounted(() => {
    rootNode.value = root.value.getRootNode();
    host.value = rootNode.value?.host;
  });

  return {
    host,
    rootNode,
  };
}
