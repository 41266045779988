import { watchImmediate } from "@vueuse/core";
import { computed, ref } from "vue";
import { trimToNull, trimToUndefined } from "../../../utils/props";
import { useDistanceFilterApi } from "../api/distance-filter";

export const useDistanceFilter = (props) => {
  const api = props.filterApi ?? useDistanceFilterApi(props);

  const geolocationRetrievalSupported = computed(() => navigator.geolocation);

  const searchQuery = ref("");

  const currentLocationId = "current-location";

  const loading = ref(false);
  const error = ref(null);

  const lat = ref(trimToUndefined(props.lat));
  const lng = ref(trimToUndefined(props.lng));

  const hasGeoCoordinates = computed(() => lat.value && lng.value);

  const selectedLocation = ref();
  const selectedDistance = ref(trimToNull(props.distance));

  const distances = ref(trimToUndefined(props.distances));
  const distanceOptions = computed(() => distances.value.map((value) => ({ value, title: `${value} miles` })));

  const near = ref(trimToUndefined(props.near));

  // If no data was provided, we immediately emit a change event on next model change.
  const immediateEmit = ref(!(hasGeoCoordinates.value || selectedDistance.value || near.value));

  const selectedDistancePrependText = ref("Within");

  const selectedDistanceText = computed(() => {
    const value = Number.parseInt(selectedDistance.value);
    const option = distanceOptions.value.find((option) => option.value === value);
    return option?.title;
  });

  const isRetrievingCurrentLocation = ref(false);

  const currentLocation = computed(() => {
    const prependIcon = "gps-locate-filled";
    const isRetrieving = isRetrievingCurrentLocation.value;

    return {
      value: currentLocationId,
      name: isRetrieving ? props.currentLocationRetrievingLabel : props.currentLocationLabel,
      props: {
        prependIcon,
        prependIconProps: {
          fillColor: "primary",
        },
        ignoreActiveState: isRetrieving,
        loading: isRetrieving,
      },
    };
  });

  /**
   * Returns the locations to prepend to the list of common or google places.
   * If geolocation retrieval is not supported, an empty array is returned, and no prepend locations are displayed.
   */
  const prependLocations = computed(() => {
    if (!geolocationRetrievalSupported.value) {
      return [];
    }
    const options = { group: " " };
    return decorateModels(options, currentLocation.value);
  });

  const commonPlaces = ref([]);
  const googlePlaces = ref([]);

  /**
   * If the selected location changes, clear the geo coordinates if the location is different from the current location.
   * This is to ensure that the geo coordinates are cleared when the user selects a different location.
   */
  watchImmediate(selectedLocation, (location) => {
    if (location && location.value !== currentLocationId) {
      clearGeoCoordinates();
    }
  });

  /**
   * Returns the locations to display in the dropdown.
   *
   * If there are google locations, they are returned grouped by "Search Results".
   * Otherwise, the common locations are returned grouped by "Common Locations".
   */
  const locations = computed(() => [
    ...prependLocations.value,
    ...(googlePlaces.value.length > 0 ? googlePlaces.value : commonPlaces.value),
  ]);

  const model = computed(() => {
    const formData = new FormData();

    const nearValue = selectedLocation.value?.value;
    if (nearValue && nearValue !== currentLocationId) {
      formData.set("near", nearValue);
    }

    // If the selected distance is set and the near value is set, we add the distance to the form data.
    // This is to ensure that the distance is only added when the near value is set.
    if (selectedDistance.value && nearValue) {
      formData.set("distance", selectedDistance.value);
    }

    if (hasGeoCoordinates.value) {
      formData.set("lat", lat.value);
      formData.set("lng", lng.value);
    }

    return formData;
  });

  /**
   * Returns the display text for the selected location.
   * This is used to display the selected location in the activator.
   *
   * The display text is constructed as follows:
   * - If there is no selected location, return undefined.
   * - If there is a selected distance, prepend the selected distance text.
   * - Append the selected location name.
   * - Join the segments with " of ".
   *
   * @returns {string | undefined}
   *
   * @example
   * // Returns "Within 5 miles of New York"
   * selectedDistance.value = 5;
   * selectedLocation.value = { name: "New York" };
   *
   * // Returns "New York"
   * selectedDistance.value = undefined;
   * selectedLocation.value = { name: "New York" };
   */
  const displayText = computed(() => {
    if (!selectedLocation.value) {
      return undefined;
    }

    const segments = [];

    if (selectedDistanceText.value) {
      segments.push(`${selectedDistancePrependText.value} ${selectedDistanceText.value}`);
    }

    const { name, value } = selectedLocation.value;
    const text = value === currentLocationId ? props.currentLocationDisplayText : name;
    segments.push(text);

    return segments.filter(Boolean).join(" of ");
  });

  async function initialize() {
    if (hasGeoCoordinates.value) {
      updateSelectedLocation(currentLocationId);
    }
    await loadCommonPlaces();
  }

  async function loadCommonPlaces() {
    loading.value = true;

    try {
      const places = await api.getCommonPlaces();
      commonPlaces.value = decoratePlaceModels("Common Locations", ...places);
      selectLocationIfAvailable();
    } catch (e) {
      error.value = e;
    } finally {
      loading.value = false;
    }
  }

  function decoratePlaceModels(group, ...models) {
    return decorateModels(
      {
        group,
        props: { prependIcon: "map-pin-filled", prependIconProps: { fillColor: "var(--sp-sys-color-primary" } },
      },
      ...models,
    );
  }

  function decorateModels(options, ...models) {
    const { group, props = {} } = options;
    return models.map((model) => ({ ...model, group, props: { ...model.props, ...props } }));
  }

  function selectLocationIfAvailable() {
    // If the near is not set or the geo coordinates are already set, we don't need to do anything.
    if (!near.value || hasGeoCoordinates.value) {
      return;
    }

    const location = findLocationByValue(near.value);

    if (location) {
      return updateSelectedLocation(location.value);
    }

    // If the location is not found, we assume it's a google place.
    // We update the search query and google result to display the location.
    searchQuery.value = near.value;

    const googlePlace = { value: near.value, name: near.value };
    updateGoogleResult([googlePlace]);
    selectedLocation.value = googlePlace;
  }

  function findLocationByValue(value) {
    return locations.value.find((location) => location.value === value);
  }

  /**
   * Determines if the current location should be retrieved.
   *
   * @param {String} locationValue
   * @returns {Boolean}
   */
  function shouldRetrieveCurrentLocation(locationValue) {
    return locationValue === currentLocationId && !hasGeoCoordinates.value;
  }

  function updateSelectedLocation(value) {
    if (shouldRetrieveCurrentLocation(value)) {
      retrieveCurrentLocation();
    } else {
      const location = findLocationByValue(value);
      selectedLocation.value = location;
    }
  }

  function unsetSelectedLocation() {
    selectedLocation.value = undefined;
  }

  /**
   * Retrieves the current location.
   * If the location retrieval is successful, the geo coordinates are updated.
   * Otherwise, the selected location is unset and an error is shown.
   */
  function retrieveCurrentLocation() {
    isRetrievingCurrentLocation.value = true;

    const cleanup = () => {
      isRetrievingCurrentLocation.value = false;
    };

    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        cleanup();
        updateGeoCoordinates(coords);
        updateSelectedLocation(currentLocationId);
      },
      () => {
        cleanup();
        unsetSelectedLocation();
        showCurrentLocationRetrievalError();
      },
    );
  }

  function showCurrentLocationRetrievalError() {
    // eslint-disable-next-line no-alert
    window.alert(props.currentLocationRetrievalErrorText);
  }

  function updateGeoCoordinates({ latitude, longitude }) {
    lat.value = latitude;
    lng.value = longitude;
  }

  function clearGeoCoordinates() {
    lat.value = undefined;
    lng.value = undefined;
  }

  /**
   * Updates the google result.
   *
   * If the result is an array, it updates the google locations.
   * In any other case, it clears the google results before proceeding.
   *
   * @param {Array<Object>|undefined} result
   */
  function updateGoogleResult(result) {
    googlePlaces.value = [];

    if (Array.isArray(result)) {
      googlePlaces.value = decoratePlaceModels("Search Results", ...result);
    }
  }

  function clear() {
    unsetSelectedLocation();
    selectedDistance.value = undefined;
    googlePlaces.value = [];

    clearGeoCoordinates();
  }
  return {
    // state
    loading,
    error,
    locations,
    model,
    selectedLocation,
    distanceOptions,
    selectedDistance,
    selectedDistancePrependText,
    displayText,
    searchQuery,
    immediateEmit,

    // actions
    initialize,
    findLocationByValue,
    updateGoogleResult,
    updateSelectedLocation,
    updateGeoCoordinates,
    clear,
  };
};
