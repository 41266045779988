<template>
  <div class="sp-expandable-area" :style="style">
    <span class="sp-expandable-area__activator" @click="isExpanded = !isExpanded">
      <span class="sp-expandable-area__activator-title">{{ activatorLabel }}</span>
      <sp-caret :active="isExpanded" />
    </span>
    <div v-if="isExpanded" class="sp-expandable-area__content">
      <slot />
    </div>
  </div>
</template>

<script setup>
/**
 * The SpExpandableArea component is used to display a section that can be expanded or collapsed.
 *
 * @displayName ExpandableArea
 * @group Custom Elements
 * @component sp-expandable-area
 */
import { computed, ref, watch } from "vue";

const emit = defineEmits(["update-expanded"]);

const props = defineProps({
  /**
   * Whether the section is expanded or not.
   *
   * @type {Boolean}
   * @default false
   */
  expanded: {
    type: Boolean,
    default: false,
  },
  /**
   * The title to display when the section is collapsed.
   *
   * @type {String}
   * @required
   */
  title: {
    type: String,
    default: "Show more",
  },
  /**
   * The title to display when the section is expanded.
   * If not provided, the title will be the same as the collapsed title.
   *
   * @type {String}
   * @default "Show less"
   */
  expandedTitle: {
    type: String,
    default: undefined,
  },

  color: {
    type: String,
    default: undefined,
  },
});

const isExpanded = ref(props.expanded);
watch(isExpanded, (value) => emit("update-expanded", value));

watch(
  () => props.expanded,
  (value) => (isExpanded.value = value),
);

const activatorLabel = computed(() => {
  if (isExpanded.value && props.expandedTitle) {
    return props.expandedTitle;
  }

  return props.title;
});

const style = computed(() => ({
  "--color": props.color,
}));
</script>

<style>
:host {
  display: block;
}
</style>

<style lang="scss" scoped>
.sp-expandable-area {
  --color: var(--sp-comp-expandable-area-activator-color, inherit);

  display: flex;
  flex-direction: column;
  width: var(--sp-comp-expandable-area-width, 100%);

  &__activator {
    cursor: pointer;
    color: var(--color);
    display: inline-grid;
    grid-template-columns: var(--sp-comp-expandable-area-activator-columns, max-content auto);
    justify-content: var(--sp-comp-expandable-area-activator-justify-content, inherit);
    align-items: center;
    gap: 0.25ch;
    font-weight: var(--sp-comp-expandable-area-activator-font-weight, 500);
    font-size: var(--sp-comp-expandable-area-activator-font-size, 0.875rem);
    margin-bottom: var(--sp-comp-expandable-area-activator-margin-bottom, 0);
    min-height: var(--sp-comp-expandable-area-activator-min-height, 0);
  }

  &__activator-title {
    display: inline-block;
  }

  sp-caret {
    display: flex;
  }
}
</style>
