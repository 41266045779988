<template>
  <div ref="root" class="sp-app-filter">
    <template v-if="isVariantFilterBar">
      <component
        :is="FilterBarWrapper"
        ref="filterInstance"
        v-bind="attrs"
        v-model="model"
        horizontal-align="left"
        :filter="filter"
        :open="open"
        @update:open="open = $event"
        @update:active="active = $event"
      />
    </template>

    <template v-else>
      <component :is="filter" ref="filterInstance" v-bind="attrs" v-model="model" :open="open" />
    </template>
  </div>
</template>

<script>
import DateFilter from "../shared/components/DateFilter.ce.vue";
import DistanceFilter from "../shared/components/DistanceFilter.ce.vue";
import InsurancePlanFilter from "../shared/components/InsurancePlanFilter.ce.vue";

/**
 * All available filters.
 * The filter instance will be dynamically loaded based on the filter type prop.
 *
 * @type {Object}
 */
const availableFilters = {
  insurance: InsurancePlanFilter,
  distance: DistanceFilter,
  date: DateFilter,
};
</script>

<script setup>
import { computed, onMounted, ref, useAttrs, watch } from "vue";
import { useExpose } from "../../composables/expose";
import { toBoolean, trimToUndefined } from "../../utils/props";
import FilterBarWrapper from "./components/FilterBarWrapper.ce.vue";

const emit = defineEmits(["update-active", "update-open", "change", "close"]);

const attrs = useAttrs();

const props = defineProps({
  /**
   * The type of the filter.
   *
   * @type {String} - The type of the filter. One of: insurance, distance, date.
   * @required
   */
  type: {
    type: String,
    validator: (value) => Object.keys(availableFilters).includes(value),
    required: true,
  },
  variant: {
    type: String,
    default: "default",
    validator: (value) => ["default", "filter-bar"].includes(value),
  },
  open: {
    type: [Boolean, String],
    default: false,
  },
});

const model = ref(undefined);
watch(model, (value) => {
  emit("change", value);
});

const isVariantFilterBar = computed(() => props.variant === "filter-bar");

const filter = computed(() => availableFilters[trimToUndefined(props.type)]);

const active = ref(false);

watch(active, (value) => {
  emit("update-active", value);
});

const open = ref(toBoolean(props.open));
watch(
  () => props.open,
  (value) => (open.value = toBoolean(value)),
);

const root = ref(null);

watch(open, (value) => {
  emit("update-open", value);

  if (value && isVariantFilterBar.value) {
    /**
     * Scroll the filter into view when the filter bar is opened.
     */
    scrollIntoViewIfNeeded();
  } else if (!value) {
    removeOpenAttribute();
    emit("close");
  }
});

/**
 * Remove the open attribute from the root element.
 * This is necessary to keep the reactivity of the open attribute.
 *
 * TODO: Remove once the filter menu is migrated to the new filter.
 */
function removeOpenAttribute() {
  root.value.getRootNode()?.host?.removeAttribute("open");
}

function scrollIntoViewIfNeeded() {
  root.value.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
}

const filterInstance = ref(null);
const filterInstanceProps = computed(() => filterInstance.value?.component?.props);

const { exposeProperties, exposeMethods } = useExpose(root);

/**
 * Compatability with the filter menu.
 * TODO: Remove once all filters are migrated to the new filter.
 */
exposeProperties({
  isFilterApp: { value: true },
  prependIcon: {
    get: () => filterInstanceProps.value?.prependIcon,
  },
  value: {
    set: (value) => (model.value = trimToUndefined(value)),
    get: () => model.value,
  },
  active: {
    get: () => active.value,
  },
  label: {
    get: () => attrs.label,
  },
});

/**
 * Compatability with the filter menu.
 * TODO: Remove once all filters are migrated to the new filter.
 */
exposeMethods({
  deactivate() {
    model.value = undefined;
  },
});

onMounted(() => {
  if (attrs.value) {
    model.value = trimToUndefined(attrs.value);
  }
});
</script>

<style>
:host(:focus),
:host(:focus-visible) {
  outline: none;
  box-shadow: none !important;
}
</style>
