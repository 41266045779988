<template>
  <component
    :is="layoutComponent"
    v-if="show"
    :model="store.status"
    :physicians-url="physiciansUrl"
    :physician-path="physicianPath"
  />
</template>

<script setup>
import { useLocalStorage } from "@vueuse/core";
import { computed, onMounted, ref, watch } from "vue";
import CompactLayout from "./components/CompactLayout.ce.vue";
import TinyLayout from "./components/TinyLayout.ce.vue";
import WideLayout from "./components/WideLayout.ce.vue";
import { useSharedStatusStore, useStatusStore } from "./store/status";

const props = defineProps({
  status: {
    type: String,
    default: undefined,
    validator: (value) => ["accepted", "no-provider-data", "unknown", "not-accepted"].includes(value),
  },
  insurancePlan: {
    type: String,
    default: undefined,
  },
  provider: {
    type: String,
    default: undefined,
  },
  api: {
    type: String,
    default: undefined,
  },
  variant: {
    type: String,
    default: "wide",
    validator: (value) => ["tiny", "compact", "wide"].includes(value),
  },
  static: {
    type: Boolean,
    default: false,
  },
  physiciansUrl: {
    type: String,
    default: undefined,
  },
  physicianPath: {
    type: String,
    default: undefined,
  },
});

const layouts = {
  tiny: TinyLayout,
  compact: CompactLayout,
  wide: WideLayout,
};

const layoutComponent = computed(() => layouts[props.variant] ?? WideLayout);

const store = props.static ? useStatusStore(props) : useSharedStatusStore({ url: props.api });

const storedInsurancePlanFilter = useLocalStorage("insurancePlanFilter", null);

const render = ref(false);
const show = computed(() => render.value && store.status !== null);

async function loadStatus() {
  // Load the status from the API if it's provided
  if (props.api) {
    await store.load?.(storedInsurancePlanFilter.value);
  }
}

watch(storedInsurancePlanFilter, loadStatus);

onMounted(async () => {
  await loadStatus();

  render.value = true;
});
</script>

<style>
:host {
  display: block;
}

:host(:focus) {
  outline: none;
  box-shadow: none !important;
}
</style>
