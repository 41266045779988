<template>
  <sp-alert v-if="!model.isSelfPay" :variant="variant" :type="model.type" :append-align="appendAlign">
    <sp-icon slot="prepend" class="prepend-icon" :name="model.icon"></sp-icon>

    <div class="content">
      <sp-alert-content>
        <template v-if="model.isUndefined">
          <strong>Add an insurance plan</strong> to to see if this this provider is in your network.
        </template>

        <template v-else>
          <WideLayoutContent :model="model" />

          <InsurancePlanFilterInline title="Change Plan" />

          <p v-if="model.isNotAccepted">
            You may have a higher copay or be responsible for the full cost of your visit.
          </p>
        </template>
      </sp-alert-content>
      <InsurancePlanFilterOutlined v-if="model.isUndefined" />
    </div>
  </sp-alert>

  <div ref="footer" class="footer" :class="breakpointModifierClass">
    <sp-button v-if="showGoToProviderFinder" @click="gotToProviderFinder">Find In-Network Providers</sp-button>

    <ProviderInsurancePlansDialog
      v-if="!model.isNoProviderData"
      :model="model"
      class="dialog"
      :physician-path="physicianPath"
    />
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useBreakpoints } from "../../../composables/breakpoints";
import InsurancePlanFilterInline from "./InsurancePlanFilterInline.ce.vue";
import InsurancePlanFilterOutlined from "./InsurancePlanFilterOutlined.ce.vue";
import ProviderInsurancePlansDialog from "./ProviderInsurancePlansDialog.ce.vue";
import WideLayoutContent from "./WideLayoutContent.ce.vue";

const props = defineProps({
  model: {
    type: Object,
    required: true,
  },
  physiciansUrl: {
    type: String,
    required: true,
  },
  physicianPath: {
    type: String,
    required: true,
  },
});

const appendAlign = computed(() => (props.model.isUndefined ? "center" : undefined));
const variant = computed(() => (props.model.isUndefined ? "elevated" : undefined));

const showGoToProviderFinder = computed(() => !props.model.isAccepted && !props.model.isUndefined);

function gotToProviderFinder() {
  const { searchParams } = new URL(document.location.href);
  const searchUrl = searchParams.get("search_url");

  window.location = searchUrl ?? props.physiciansUrl;
}
const footer = ref(null);
const { isGtSmScreen } = useBreakpoints(footer);

const breakpointModifierClass = computed(() => ({
  "--bp-lt-sm": !isGtSmScreen.value,
}));
</script>

<style>
:host {
  display: block;
}
</style>

<style lang="scss" scoped>
.content {
  display: grid;
  grid-template-columns: 1fr max-content;
  column-gap: 0.5ch;
}

@media screen and (max-width: 1000px) {
  .content {
    grid-template-columns: 1fr;
    column-gap: 0;
    row-gap: 0.5rem;
  }
}

.footer {
  display: flex;
  align-items: center;
  gap: 1ch;
  margin-top: 1.5rem;

  &.--bp-lt-sm {
    flex-direction: column;
    gap: 0.5rem;
    .dialog,
    sp-button {
      --sp-comp-button-width: 100%;
    }
  }
}
</style>
