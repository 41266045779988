<template>
  <BasePanel>
    <template v-if="clientSecret">
      <sp-payment-element
        v-if="paymentRequired"
        ref="paymentElement"
        hide-pay-button
        hide-error
        :publishable-key="publishableKey"
        :client-secret="clientSecret"
        :return-url="returnUrl"
      />
      <sp-alert v-else type="info"> No payment required </sp-alert>
    </template>
  </BasePanel>
</template>

<script setup>
import { watchImmediate } from "@vueuse/core";
import { computed, ref } from "vue";
import { usePurchaseStore } from "../store/purchase";
import BasePanel from "./BasePanel.ce.vue";

const purchaseStore = usePurchaseStore();

const paymentElement = ref(null);
const paymentRequired = computed(() => purchaseStore.paymentRequired.value);
const publishableKey = computed(() => purchaseStore.publishableKey.value);
const clientSecret = computed(() => purchaseStore.clientSecret.value);
const returnUrl = computed(() => purchaseStore.returnUrl.value);

watchImmediate(paymentElement, (element) => {
  if (element) {
    purchaseStore.setPaymentElement(element);
  }
});
</script>

<style>
sp-payment-element {
  margin-top: 2rem;
}
</style>
