<template>
  <div ref="root" part="base">
    <!-- eslint-disable vue/no-v-html -->
    <div v-if="svgNode" ref="customContainer" :class="classModifiers" class="sp-icon" v-html="svgNode"></div>
    <!-- eslint-enable vue/no-v-html -->

    <component :is="tag" v-else-if="iconHref" v-bind="$attrs" class="icon-wrapper">
      <svg class="icon" :view-box="viewBox" :class="classModifiers">
        <use :href="iconHref" />
      </svg>
    </component>

    <div v-else :class="classModifiers" class="sp-icon">
      <slot ref="customContainer" />
    </div>
  </div>
</template>

<script setup>
import { useMutationObserver, watchImmediate, watchOnce } from "@vueuse/core";
import { computed, onMounted, ref } from "vue";
import { useColorToCssProperty } from "../../composables/color";
import { useSize } from "../../composables/size";
import { fetchIcons } from "../../utils/icons";

const icons = ref(null);

const props = defineProps({
  embedHost: {
    type: String,
    required: false,
    default: undefined,
    external: true,
  },
  name: {
    type: String,
    required: false,
    default: undefined,
  },
  svg: {
    type: String,
    required: false,
    default: undefined,
  },
  url: {
    type: String,
    default: undefined,
  },
  fillColor: {
    type: String,
    default: undefined,
  },
  spriteUrl: {
    type: String,
    external: true,
    default: undefined,
  },
  viewBox: {
    type: String,
    default: "0 0 32 32",
  },
  size: {
    type: String,
    // FIXME: @mgruschetzki: This is not working as expected.
    // validator: (value) =>
    //   ["3xs", "2xs", "1xs", "xs", "s", "m", "l", "xl", "1xl", "2xl", "3xl", "4xl", "5xl"].includes(value),
    default: undefined,
  },
  tag: {
    type: String,
    default: "div",
  },
});

const root = ref(null);
const customContainer = ref(null);

const mutationObserverStop = ref(null);

watchImmediate(
  () => props.fillColor,
  () => {
    if (!props.fillColor) {
      mutationObserverStop.value?.();
      return;
    }

    /**
     * If a fill color prop was set, we need to update the fill color of the SVG.
     * We wait until the color composable has detected the color to be used which results in a CSS variable being set.
     * Once the variable is set, the observer will be notified and the setFillColor function will be called.
     */
    const { stop } = useMutationObserver(root, setFillColor, {
      attributes: true,
      attributeFilter: ["style"],
      subtree: true,
      childList: true,
    });
    mutationObserverStop.value = stop;

    watchOnce(root, (value) => {
      if (value) {
        setFillColor();
      }
    });

    function setFillColor() {
      const property = "fill";
      const color = root.value.style.getPropertyValue(`--${property}`);

      root.value.querySelectorAll("path, use").forEach((path) => {
        if (color) {
          path.setAttribute(property, color);
        } else {
          path.removeAttribute(property);
        }
      });
    }
  },
);

useColorToCssProperty(props, root, { propName: "fillColor", cssProperty: "fill" });

const svgSprite = computed(() => icons.value?.[props.name]);
const svgNode = computed(() => props.svg ?? svgSprite.value);

const classNames = computed(() => ({ [`icon--${props.name}`]: props.name, "theme--dark": props.dark }));

const iconSpriteUrl = computed(() => props.spriteUrl ?? window.iconSpriteUrl?.());
const iconHref = computed(() => {
  if (!iconSpriteUrl.value || !props.name) {
    return undefined;
  }
  return `${iconSpriteUrl.value}#icon-${props.name}`;
});

const { sizeModifiers } = useSize(props);
const classModifiers = computed(() => [classNames.value, ...sizeModifiers.value]);

watchImmediate(() => props.url, fetchIconIfAvailable);

async function fetchIconIfAvailable() {
  if (iconSpriteUrl.value && props.name) {
    return;
  }
  if (props.name) {
    icons.value = await fetchIcons(props.embedHost);
  } else if (props.url) {
    const response = await fetch(props.url);
    customContainer.value.innerHTML = await response.text();
  }
}

onMounted(async () => {
  await fetchIconIfAvailable();
});
</script>

<style>
:host {
  --fill: var(--sp-sys-icon-fill, black);
  --size: var(--sp-sys-icon-size-m, 2rem);
  --font-size: var(--sp-icon-font-size, 1rem);
  display: block;
  font-size: var(--font-size);
}

::slotted(svg),
svg {
  height: var(--size);
  width: auto;
  width: var(--size);
  fill: var(--fill);
}
</style>

<style lang="scss" scoped>
.sp-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: var(--size);
  width: var(--size);
  max-height: var(--size);
  max-width: var(--size);

  // Sizes
  @each $size in (3xs 2xs xs s m l xl 2xl 3xl 4xl 5xl) {
    &.--size-#{$size} {
      --size: var(--sp-sys-icon-size-#{$size}, 2rem);
    }
  }
}

.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  --icon-size: var(--size);
}
</style>
