import { useMediaQuery, useMutationObserver, watchImmediate } from "@vueuse/core";
import { computed, ref } from "vue";

function getPropertyValue(propery) {
  return window.getComputedStyle(document.documentElement).getPropertyValue(propery);
}

/**
 * Create a callback function to be called when the breakpoint changes
 * and update the receiver ref with the new value.
 *
 * It also watches the media query to update the receiver ref when the
 * media query changes.
 *
 * It keeps track of the watcher to unwatch it when the breakpoint changes.
 *
 * @param {import("vue").Ref} receiver
 * @param {import("vue").Ref} breakpoint
 * @returns Function
 */
function createOnChangeBreakpointCallback(receiver, breakpoint) {
  let unwatch;

  return () => {
    unwatch?.();

    const watched = useMediaQuery(`(min-width: ${breakpoint.value})`);

    unwatch = watchImmediate(watched, (value) => {
      receiver.value = value;
    });
  };
}

/**
 * A composable to get the current breakpoints and if the screen is greater than
 * a specific breakpoint.
 *
 * @returns {{
 *  smBreakpoint: import("vue").Ref<string>,
 *  xlBreakpoint: import("vue").Ref<string>,
 *  isGtSmScreen: import("vue").Ref<boolean>,
 *  isGtXlScreen: import("vue").Ref<boolean>,
 * }}
 */
export function useBreakpoints() {
  const smBreakpoint = ref("");
  const isGtSmScreen = ref(false);
  watchImmediate(smBreakpoint, createOnChangeBreakpointCallback(isGtSmScreen, smBreakpoint));

  const xlBreakpoint = ref("");
  const isGtXlScreen = ref(false);
  watchImmediate(xlBreakpoint, createOnChangeBreakpointCallback(isGtXlScreen, xlBreakpoint));

  const { stop } = useMutationObserver(document.documentElement, setBreakpointValues, {
    subtree: true,
    attributes: true,
    attributeFilter: ["class", "style"],
  });

  /**
   * Set the breakpoint values from the CSS variables.
   */
  function setBreakpointValues() {
    smBreakpoint.value = getPropertyValue("--sp-ref-breakpoint-sm");
    xlBreakpoint.value = getPropertyValue("--sp-ref-breakpoint-xl");

    // Stop the observer when the breakpoints are set, this is only needed once
    if (smBreakpoint.value && xlBreakpoint.value) {
      stop?.();
    }
  }

  const isSmScreen = computed(() => isGtSmScreen.value === false);
  const isXlScreen = computed(() => isGtXlScreen.value === true);

  // Detect the breakpoints when the composable is created, this speeds up the initial detection
  setBreakpointValues();

  return {
    smBreakpoint,
    xlBreakpoint,
    isSmScreen,
    isXlScreen,
    isGtSmScreen,
    isGtXlScreen,
  };
}
