<template>
  <div class="sp-card-content">
    <slot />
  </div>
</template>

<script setup></script>

<style>
:host {
  display: block;
  height: 100%;
}
</style>

<style scoped>
.sp-card-content {
  display: block;
  padding-inline: var(--sp-comp-card-padding-inline, 1rem);
  padding-block: var(--sp-comp-card-padding-block, 0.5rem);
  position: relative;
  height: 100%;
}
</style>
