import { watchOnce, whenever } from "@vueuse/core";
import { ref, toValue, watch } from "vue";
import { useHost } from "./host";

export function useForm(root) {
  const { host } = useHost(root);

  const internals = ref(null);
  const form = ref(null);

  watch(host, (host) => {
    if (!host || internals.value) {
      return;
    }

    try {
      internals.value = host.attachInternals();
    } catch (error) {
      // noop
    }
  });

  watch(internals, (internals) => {
    form.value = internals?.form;
  });

  /**
   * Updates the form value.
   *
   * @param {any} value - The value to set.
   * @param {*} options
   * @param {boolean} [options.emit] - Whether to emit a change event. Defaults to `true`.
   */
  function setValue(value, options) {
    performOrScheduleFormUpdate(toValue(value), options);
  }

  /**
   * Performs or schedules a form update.
   * If the internals are not yet available, the form update is scheduled.
   *
   * @private
   */
  function performOrScheduleFormUpdate(value, options) {
    if (internals.value) {
      performFormUpdate(value, options);
    } else {
      scheduledFormUpdate(value, options);
    }
  }

  function performFormUpdate(value, options) {
    internals.value.setFormValue(value);

    if (options?.emit !== false) {
      // Dispatch a change event to notify the form element of the value change.
      const event = new Event("change", { bubbles: true });
      host.value?.dispatchEvent(event);
    }
  }

  /**
   * A tuple of the value and options to be used for the next form update.
   * This is used to schedule a form update when the internals are not yet available.
   */
  let scheduledFormUpdateProperties;

  function scheduledFormUpdate(value, options) {
    scheduledFormUpdateProperties = [value, options];
  }

  function runScheduledFormUpdate() {
    if (scheduledFormUpdateProperties) {
      performOrScheduleFormUpdate(...scheduledFormUpdateProperties);
      scheduledFormUpdateProperties = undefined;
    }
  }

  /**
   * Whenever the internals are available, the scheduled form update is performed.
   */
  whenever(internals, runScheduledFormUpdate);

  /**
   * Watches the model for changes and updates the form value.
   * On the first change, the form value is set but no change event is emitted.
   * Subsequent changes will update the form value and emit a change event.
   *
   * The initial change only populates the form value with the provided model value,
   * which is initialized from the `value` attribute of the form element (the custom element using this composable).
   * Since the custom element behaves like an HTML form element, the form value must be initialized silently.
   *
   * @param {import('vue').Ref} model - The model to watch.
   * @param {Function} [callbackFn] - The callback function to determine if the form value should be updated. (optional)
   * The callback function is called with the model value. Defaults to `(value) => true`.
   * If the callback function returns false, the form value is not set.
   * Any other value updates the form value.
   * @param {Object} [options] - The options object. (optional)
   * @param {boolean} [options.immediate] - Whether to emit the change event immediately. Defaults to `false`.
   *
   * @example
   * ```js
   * const model = ref(null);
   * syncFormData(model, (value) => value !== null);
   * ```
   */
  function syncFormData(model, callbackFnOrOptions, options) {
    const callbackFn = typeof callbackFnOrOptions === "function" ? callbackFnOrOptions : () => true;
    const { immediate = false } = (typeof callbackFnOrOptions === "object" ? callbackFnOrOptions : options) ?? {};

    if (immediate) {
      return syncFormDataWithImmediateEmit(model, callbackFn);
    }

    watchOnce(model, (value) => {
      handleModelChange(value, false, callbackFn);
      syncFormDataWithImmediateEmit(model, callbackFn);
    });
  }

  /**
   * Same as `syncFormData`, but emits the change event immediately.
   * This is useful when the form value should be updated immediately after the model value changes.
   *
   * @see {@link syncFormData}
   */
  function syncFormDataWithImmediateEmit(model, callbackFn) {
    watch(model, (value) => handleModelChange(value, true, callbackFn));
  }

  function handleModelChange(value, emit, callbackFn = () => true) {
    if (callbackFn(value) !== false) {
      setValue(value, { emit });
    }
  }

  return {
    // Properties
    internals,
    form,

    // Methods
    setValue,
    syncFormData,
    syncFormDataWithImmediateEmit,
  };
}
