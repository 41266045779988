<template>
  <component :is="tag" class="sp-text" :class="classModifiers">
    <slot />
  </component>
</template>

<script>
const defaultType = "body-1";
const types = [
  defaultType,
  "h1",
  "h2",
  "h3",
  "h4",
  "title",
  "subtitle",
  "body",
  "body-semi-bold",
  "body-bold",
  "link",
  "small",
  "small-bold",
  "footnote",
  "footnote-medium",
  "footnote-bold",
  "label",
  "custom",
];
</script>

<script setup>
import { computed } from "vue";

const props = defineProps({
  tag: {
    type: String,
    default: "div",
  },
  type: {
    type: String,
    default: undefined,
    validator: (value) => types.includes(value),
  },
  color: {
    type: String,
    default: undefined,
  },
});

const type = computed(() => {
  if (props.type) {
    return props.type;
  }
  return types.includes(props.tag) ? props.tag : defaultType;
});

const classModifiers = computed(() => ({
  [`--${type.value}`]: type.value,
}));
</script>

<style>
:host {
  display: block;
  margin: 0;
  box-sizing: border-box;
  --text-color: var(--sp-comp-text-color, var(--sp-sys-color-on-surface, #000));
}
:host([color]) {
  --text-color: v-bind(color);
}
:host([type="custom"]) {
  --font: var(--sp-comp-text-custom-font-short, var(--sp-sys-typescale-body-short));
}
</style>

<style lang="scss" scoped>
%reset {
  margin: 0;
  padding: 0;
}

.sp-text,
.--body {
  color: var(--text-color);
  font: var(--sp-sys-typescale-body-short);
  @extend %reset;
}

$types: h1, h2, h3, h4, "body", "body-semi-bold", "body-bold", "link", "small", "small-bold", "footnote",
  "footnote-medium", "footnote-bold", "label";

@each $type in $types {
  .--#{$type} {
    font: var(--sp-sys-typescale-#{$type}-short);
  }
}

.--title,
.--subtitle,
.--label,
.--custom {
  @extend %reset;
}

.--title {
  font: var(--sp-comp-text-title-short, var(--sp-sys-typescale-h4-short));
}

.--subtitle {
  font: var(--sp-comp-text-subtitle-short, var(--sp-sys-typescale-body-semi-bold-short));
}

.--label {
  font: var(--sp-comp-text-label-short, var(--sp-sys-form-field-label-short));
}

.--custom {
  font: var(--font);
}

.--link {
  text-decoration: var(--sp-comp-text-link-text-decoration, var(--sp-sys-typescale-link-text-decoration, underline));
}
</style>
