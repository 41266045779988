<template>
  <div ref="root" class="insurance-plan-filter" :class="classModifiers">
    <sp-quantum-select
      clearable
      no-filter
      item-title="text"
      mobile-expand-height-max
      no-data-text="No results found"
      :close-on-select="closeOnSelect"
      :has-parent="hasParent"
      :hide-search-bar="hideSearchBar"
      :hide-title-bar="hideTitleBar"
      :items.prop="models"
      :loading="loading"
      :error="error"
      :location="location"
      :open="open"
      :permanent="permanent"
      :pointer="!hidePointer"
      :provide="provide"
      :readonly="readonly"
      :selected="[selectedModel?.value]"
      :title="stepTitle"
      :width="width"
      :max-height="maxHeight"
      @update-search="handleUpdateSearch"
      @update-selected="handleUpdateSelected"
      @update-open="handleUpdateOpen"
    >
      <div slot="activator">
        <slot name="activator" :label="label" :text="selectedModelText" :prepend-icon="prependIcon" :open="open">
          <DefaultFilterActivator
            :value="activatorTitle"
            :label="label"
            :prepend-icon="prependIcon"
            @clear="clearSelectedModel"
          />
        </slot>
      </div>
      <sp-linear-progress-bar
        v-if="!hideProgressBar"
        slot="before-content"
        :value="progress"
        color="var(--sp-sys-color-success)"
        rounded
        rounded-bar
        animation-time="var(--app-slide-animation-time)"
      />
    </sp-quantum-select>
  </div>
</template>

<script setup>
import { useLocalStorage } from "@vueuse/core";
import { computed, onMounted, ref, watch } from "vue";
import { toBoolean } from "../../../utils/props";
import { useInsuranceFilterStore } from "../store/insurance-filter";
import DefaultFilterActivator from "./DefaultFilterActivator.ce.vue";

const emit = defineEmits(["update:model-value", "update:open"]);

const props = defineProps({
  modelValue: {
    type: [String, Number, Boolean, Object, Array],
    default: undefined,
  },
  /**
   * The form element name of the component.
   */
  name: {
    type: String,
    default: "app-filter",
  },
  /**
   * The label of the activator.
   *
   * @type {String}
   */
  label: {
    type: String,
    default: "Insurance",
  },
  /**
   * The icon to prepend to the activator.
   *
   * @type {String}
   */
  prependIcon: {
    type: String,
    default: "insurance-carrier-filled",
  },
  /**
   * Hide the progress bar.
   *
   * @type {Boolean}
   * @default false
   */
  hideProgressBar: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Hide the search bar.
   *
   * @type {Boolean}
   * @default false
   */
  hideSearchBar: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Hide the title bar.
   *
   * @type {Boolean}
   * @default false
   */
  hideTitleBar: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * The sections to fetch the data from.
   *
   * @context api
   */
  sections: {
    type: [Array, String],
    default: "insurance_plans",
  },
  /**
   * The base url to fetch the data from.
   *
   * @context api
   */
  baseUrl: {
    type: String,
    default: "/physicians/filters",
  },
  /**
   * The API to fetch the data from.
   *
   * @context api
   */
  api: {
    type: String,
    default: "provider_search",
  },
  /**
   * The url to fetch the data from.
   * If provided, it will override the `baseUrl`, `sections` and `api` props.
   * @context api
   */
  url: {
    type: String,
    default: undefined,
  },
  hidePointer: {
    type: Boolean,
    default: false,
  },
  open: {
    type: Boolean,
    default: false,
  },
  location: {
    type: String,
    default: undefined,
  },
  inline: {
    type: Boolean,
    default: false,
  },
  closeOnSelect: {
    type: Boolean,
    default: false,
  },
  permanent: {
    type: [Boolean, String],
    default: false,
  },
  width: {
    type: String,
    default: undefined,
  },
  maxHeight: {
    type: String,
    default: undefined,
  },
  readonly: {
    type: [Boolean, String],
    default: false,
  },
  startOnRoot: {
    type: [Boolean, String],
    default: false,
  },
});

defineExpose({ props });

const hideProgressBar = computed(() => toBoolean(props.hideProgressBar));
const hideSearchBar = computed(() => toBoolean(props.hideSearchBar));

const root = ref(null);

const store = useInsuranceFilterStore(props);
const { stepTitle, models, selectedModel, hasParent, progress, searchQuery, loading, error } = store;

if (toBoolean(props.readonly)) {
  onMounted(async () => await store.load());
} else {
  const session = useLocalStorage("insurancePlanFilter", null);

  onMounted(async () => {
    await store.load(props.modelValue);

    // If the `startOnRoot` prop is set to `true`, we will not set the selected model in order to start on the root.
    // Otherwise, we will set the selected model to the model value or the session value, which will cause the component
    // to present the nested insurance plans.
    if (!toBoolean(props.startOnRoot)) {
      selectedModel.value = store.findByValue(props.modelValue ?? session.value);
    }
  });

  watch(selectedModel, () => {
    const value = selectedModel.value?.value;
    emit("update:model-value", value);

    session.value = value ?? null;
  });
}

watch(
  () => props.modelValue,
  (value) => (selectedModel.value = store.findByValue(value)),
);

const selectedModelText = computed(() => selectedModel.value?.text);
const activatorTitle = computed(() => selectedModelText.value);

function handleUpdateSearch({ detail }) {
  const [value] = detail;
  searchQuery.value = value;
}

const open = ref(props.open);

function handleUpdateSelected({ detail }) {
  const [selected] = detail;
  const [value] = selected;
  selectedModel.value = store.findByValue(value);
}

function clearSelectedModel() {
  selectedModel.value = null;
}

watch(
  () => props.open,
  (value) => {
    open.value = toBoolean(value);
  },
);

watch(open, () => {
  adjustStartParent();
  emit("update:open", open.value);
});

/**
 * Adjust the start parent.
 *
 * If the `startOnRoot` prop is set to `true`, it will pop the parent, which will cause the component
 * to present the carriers.
 */
function adjustStartParent() {
  if (toBoolean(props.startOnRoot)) {
    store.popParent(true);
  }
}

function handleUpdateOpen({ detail }) {
  const [isOpen] = detail;
  open.value = isOpen;
}

async function provide(type, item) {
  if (type === "parent") {
    store.popParent();
  } else {
    store.pushParent(item.value);
  }
}

const classModifiers = computed(() => ({
  "--inline": props.inline,
  "--has-selected-model": !!selectedModel.value,
}));
</script>

<style>
:host {
  --app-slide-animation-time: 0.3s;
  --filter-width: 100%;
  display: block;
  box-sizing: border-box;
}
:host([variant="filter-bar"]) {
  --filter-width: max-content;
}

:host([variant="select"]) {
  display: inline-flex;
}
</style>

<style lang="scss" scoped>
sp-quantum-select {
  --quantum-select-navigation-stack-animation-time: var(--app-slide-animation-time);
  --sp-comp-list-item-prepend-margin-right: 0.5rem;
  height: 100%;
}

sp-linear-progress-bar {
  margin: 0 0.75rem 0.75rem 0.75rem;
}

.insurance-plan-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  &.--inline {
    display: inline-flex;
    --filter-width: auto;
    align-self: center;
  }
  &.--has-selected-model {
    .activator {
      --sp-comp-text-field-color: var(--sp-sys-color-on-surface);
    }
  }
}

sp-quantum-select,
.activator,
.insurance-plan-filter {
  --sp-comp-button-width: var(--filter-width);

  width: var(--filter-width);
}
</style>
