<template>
  <div class="root">
    <sp-label v-if="label" block for="input">{{ label }}</sp-label>
    <sp-search-box
      id="input"
      clearable
      :value="searchQuery"
      :density="inputDensity"
      @text-input="handleTextInput"
      @keydown.space.stop
      @keydown.enter.stop
    ></sp-search-box>
  </div>
</template>

<script>
import { props as searchBoxProps } from "../SpSearchBox/props.js";
</script>

<script setup>
import { whenever } from "@vueuse/core";
import { computed, onMounted, ref, watch } from "vue";
import { useGoogleMaps } from "./composables/google-maps";

const emit = defineEmits(["update-model-value"]);

const props = defineProps({
  apiKey: {
    type: String,
    required: true,
    external: true,
  },
  fields: {
    type: Array,
    default: () => ["place_id"],
  },
  types: {
    type: Array,
    default: () => ["geocode"],
  },
  componentRestrictions: {
    type: Object,
    default: () => ({ country: "us" }),
  },
  label: {
    type: String,
    default: undefined,
  },
  searchQuery: {
    type: String,
    default: "",
  },
  inputDensity: searchBoxProps.density,
});

const { maps } = useGoogleMaps(props);

const model = ref(null);
watch(model, () => emit("update-model-value", model.value));

const autocompleteService = ref();

const isReady = computed(() => maps.value);

function initializAutocompleteIfReady() {
  if (isReady.value) {
    initializAutocompleteService();
  }
}

function initializAutocompleteService() {
  autocompleteService.value = new maps.value.places.AutocompleteService();
}

function handleTextInput({ detail }) {
  if (!isReady.value) {
    return;
  }

  const [query] = detail;

  if (!query) {
    model.value = [];
    return;
  }

  searchPlaces(query);
}

function searchPlaces(query) {
  const options = {
    input: query,
    fields: props.fields,
    types: props.types,
    componentRestrictions: props.componentRestrictions,
  };
  autocompleteService.value.getPlacePredictions(options, handlePredictions);
}

function handlePredictions(predictions, status) {
  const hasPredictions = status === maps.value.places.PlacesServiceStatus.OK;
  model.value = hasPredictions ? predictions.map(predictionToModelEntry) : [];
}

/**
 * Convert a prediction object from the Google Maps Places API to a model entry.
 *
 * @param prediction
 * @returns {{name: string, value: string, selectedText: string}}
 */
function predictionToModelEntry(prediction) {
  // Remove the ", USA" suffix from the description, hard-coded for now
  const value = prediction.description?.replace(/, USA$/, "");
  const selectedText = prediction.structured_formatting?.main_text;

  return { name: value, value, selectedText };
}

/**
 * Initialize the autocomplete service if the Google Maps API is ready.
 * Otherwise, wait for the API to be ready.
 */
onMounted(initializAutocompleteIfReady);

whenever(isReady, initializAutocompleteService);
</script>

<style>
:host {
  display: block;
  box-sizing: border-box;
}
</style>

<style lang="scss" scoped>
sp-label {
  margin-bottom: var(--sp-comp-google-maps-auto-complete-label-margin-bottom, var(--sp-ref-spacing-3));
}
</style>
