<template>
  <div class="broadcast-title-content">
    <slot />
  </div>
</template>

<style>
:host {
  display: inline;
  margin: 0;
}
</style>

<style lang="scss" scoped>
.broadcast-title-content {
  display: inline;
  font-family: var(--sp-comp-broadcast-message-title-typeface, var(--sp-ref-font-family-bold, inherit));
  font-size: var(--sp-comp-broadcast-message-title-size, 1rem);
  font-weight: var(--sp-comp-broadcast-message-title-weight, normal);

  * {
    margin: 0;
  }

  strong {
    font-weight: bolder;
  }
}
</style>
