<template>
  <sp-text tag="label" :for="props.for" class="sp-label">
    <slot />
  </sp-text>
</template>

<script setup>
const props = defineProps({
  for: {
    type: String,
    default: undefined,
  },
});
</script>

<style>
:host {
  display: inline;
  margin: 0;
}

:host([block]:not([block="false"])) {
  display: block;
}
</style>
